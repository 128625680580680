import React from "react";
import GoogleMaps from "../GoogleMaps/GoogleMaps";
import { placeholderIcon } from "../../img/index.js";

import { clickedOn } from "../TrackingEvents.js";

const ContactUs = () => {
  return (
    <div className="sixth-section-container">
      <hr className="break-line" />
      <h2 id="contact-us" className="section-title">
        Kontakt oss
      </h2>
      <div className="contact-us-container">
        <div className="Komplett-address">
          <div
            style={{
              fontSize: "28px",
              padding: "50px 0px 45px 0px",
              fontWeight: "600",
            }}
          >
            Er du klar for nye muligheter?
          </div>
          <div>
            <img style={{ paddingBottom: "25px" }} src={placeholderIcon} />
          </div>
          <div style={{ fontSize: "25px", paddingBottom: "25px" }}>
            Østre Kullerød 4,
            <br />
            3241 Sandefjord
          </div>
          <div>
            <a
              onClick={(e) =>
                clickedOn("click open application", "on contact us section")
              }
              style={{
                backgroundColor: "#58CCDD",
                borderRadius: "5px",
                fontSize: "25px",
                color: "black",
                padding: "10px",
                fontWeight: "600",
              }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSftEj7Qy6OfAM7SE9pIK7m76KiTvabxucBSRroNXhZDK1oamA/viewform"
              target="_blank"
              rel="noreferrer"
            >
              Send inn søknad
            </a>
          </div>
        </div>
        <GoogleMaps />
      </div>
    </div>
  );
};

export default ContactUs;
