import React from "react";

import "./WhoAreWe.css";

const WhoAreWe = () => {
  return (
    <div className="second-section-container">
      <h2 id="who-we-are" className="section-title">
        Hvem er vi?
      </h2>
      <div data-aos="fade-up" data-aos-duration="1500">
        <p className="text-container">
          Vi i Komplett WEB-team bygger plattformen som Komplett-butikkene
          kjører på. Teamet består av Backend- og Frontendutviklere, testere,
          DevOps, UX designere, prosjektledere, etc. Vi vedlikeholder
          plattformen og utvider funksjonaliteten stadig. Vi i Norge sitter
          samlet og jobber tett sammen, hvilket betyr mye sosialt samvær i løpet
          av en arbeidsdag.
        </p>
        <p className="text-container">
          Hos oss har vi et godt fellesskap med humor og mye moro. Vi er en
          gjeng vennlige og hjelpsomme folk, som slett ikke tar oss selv
          høytidelig, til tross for at vi er Norges største nettbutikk!
        </p>
        <p className="text-container">
          I Komplett har vi fokus på læring. Vi setter av tid og penger til
          blant annet kurs og konferanser – og vi er opptatt av å lære av egne
          feil. Ledelsen i Komplett er stadig i dialog med teammedlemmer
          angående hva de har lyst til å lære, hvordan de kan legge til rette
          for det.
        </p>
        <p className="text-container">
          Vi jobber tett med konsulenter i Poznan, og drar stadig dit på
          teambuildingsturer. På disse turene er god mat og drikke
          hovedingredienser. Vi er svært opptatte av å ha en god dialog med våre
          samarbeidspartnere, og selv om vi sitter i ulike land, føles det som
          om vi er fullverdige kolleger med felles mål og interesse for
          Komplett.
        </p>
        <div
          className="komplett-video-wrapper"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <iframe
            title="youtube-komplett-videos"
            src="https://www.youtube.com/embed/videoseries?list=PLZrO5G-qwaq0gPz-4nPw_f2RNwNYnYyBO&amp;controls=0&amp;autoplay=0&amp;origin=https://developer.komplett.no/"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default WhoAreWe;
