import React, { useState, useEffect } from "react";
import JobPosition from "./JobPosition";

import "./JobPosition.css";

const AvailableJobs = ({ jobsAvailable }) => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    async function getXmlData() {
      let url = "https://komplett.easycruit.com/export/xml/vacancy/list.xml";
      const response = await fetch(url);
      let data = await response.text();
      let parsedXml = new window.DOMParser().parseFromString(data, "text/xml");
      let htmlArray = [...parsedXml.getElementsByTagName("Vacancy")];
      let filteredArray = htmlArray.filter((params) => {
        if (
          params.getElementsByTagName("Version")[0].getAttribute("language") ===
            "nb" &&
          params.getElementsByTagName("Title")[0].innerHTML !== "Åpen søknad"
        ) {
          return {
            params,
          };
        }
      });

      let jobs = filteredArray.map((vacancy) => {
        let title = vacancy.getElementsByTagName("Title")[0].innerHTML;
        let categories = vacancy
          .getElementsByTagName("Categories")[0]
          .getElementsByTagName("Item")[1].innerHTML;
        let location = vacancy.getElementsByTagName("Location")[0].innerHTML;
        let jobUrl = vacancy
          .getElementsByTagName("Departments")[0]
          .getElementsByTagName("Department")[0]
          .getElementsByTagName("VacancyURL")[0].innerHTML;
        return {
          title: title,
          categories: categories,
          location: location,
          url: jobUrl,
        };
      });
      jobsAvailable(jobs.length);
      setJobs(jobs);
    }
    getXmlData();
  }, [jobsAvailable]);

  return (
    <ul className="job-box-wrapper">
      {Object.keys(jobs).length === 0 && "please check back here later"}
      {jobs.map((job) => {
        return (
          <JobPosition
            key={job.url}
            jobTitle={job.title}
            jobLocation={job.location}
            jobEngagement={job.categories}
            jobUrl={job.url}
          />
        );
      })}
    </ul>
  );
};

export default AvailableJobs;
