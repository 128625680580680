import React from "react";

import Vanta from "../Vanta/Vanta";

const Technology = () => {
  return (
    <div className="fith-section-container">
      <hr className="break-line" />
      <h2 id="technology" className="section-title">
        Teknologi
      </h2>
      <div className="fifth-section-container-content-wrapper">
        <div data-aos="fade-up" data-aos-duration="3000">
          <p className="text-container">
            Hos Komplett står WEB teamet fritt til å velge hvilken teknologi vi
            bruker for å utvikle butikk plattformen. Siden plattformen er delt
            opp i flere microservices, er det ikke uvanlig å lage en ny service
            ved starten av et nytt prosjekt, med separert kodebase fra resten av
            systemet. Hver gang vi starter en ny service har man mulighet til å
            forslå bruk av ny teknologi og teamet innad bestemmer hva de skal
            bruke innenfor for våre best praksis rammer. Dette gir utviklere
            frihet til å prøve ut de nyeste og beste teknologiene i markedet.
          </p>
        </div>
      </div>
      <Vanta className="vanta-frame" />
    </div>
  );
};

export default Technology;
