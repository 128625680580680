import React from "react";
import {
  miljo1,
  miljo2,
  miljo3,
  miljo4,
} from "../../../img/index.js";

import "./OurCulture.css";

const OurCulture = () => {
  return (
    <div className="fourth-section-container">
      <hr className="break-line" />
      <h2 id="our-culture" className="section-title">
        Vår Kultur
      </h2>
      <div className="fourth-section-container-content-wrapper">
        <div
          className="fourth-section-text-container"
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          Et godt arbeidsmiljø står høyt i kurs hos oss, derfor fokuserer vi mye
          på godt samspill i hverdagen. Fundamentet til dette, ligger i våre
          kulturelle verdier:
          <dl>
            <dt>Eksperimentvennlig kultur</dt>
            <dd>
              Vi har stort fokus på å finne ut hva som ikke fungerer optimalt,
              og vi er ikke redd for å gjøre endringer som vi mener vil forbedre
              vår arbeidsprosess.
            </dd>
            <dt>«Fail fast, fix fast»</dt>
            <dd>
              Feil og uhell er uunngåelig, så istedenfor å bruke masse energi på
              å unngå det uunngåelige foretrekker vi å fikse feil så raskt som
              mulig og sørge for at vi lærer av dem.
            </dd>
            <dt>Forståelse og hensyn over egoisme</dt>
            <dd>
              Vi behandler alle rundt oss med respekt og ydmykhet. Vi mener vi
              kan gjøre jobben vår enda bedre hvis vi jobber sammen.
            </dd>
            <dt>Kommunikasjon over samlokalisering</dt>
            <dd>
              Vi tror ikke hele avdelingen vil noen gang være samlet på ett
              sted, så vi streber heller etter god kommunikasjon og samarbeid på
              tvers av lokasjoner.
            </dd>
            <dt>«Build together, owned together»</dt>
            <dd>
              Vi er lidenskapelig opptatt av alt vi bygger. Vi unngår å tenke at
              noe ikke er vårt problem.
            </dd>
            <dt>Unngå klaging og fiks problemet</dt>
            <dd>
              Vi mener klaging har negativ innvirkning på problemløsing, så vi
              liker å fokusere på problemløsning i stedet.
            </dd>
            <dt>Tillitt over kontroll</dt>
            <dd>
              Vi mener detaljstyring er upraktisk og skaper dårlig stemning.
              Isteden setter vi vår tillitt til folk og deres evner.
            </dd>
            <dt>Åpen og ærlig</dt>
            <dd>Vi er ivrige til å forstå og verdsette sannheten.</dd>
            <dt>Glade mennesker lager bra ting</dt>
            <dd>
              En trist og lei ansatt leverer ikke et godt system. Å trives på
              jobb er den beste indikatoren på om du er på vei mot suksess.
            </dd>
          </dl>
        </div>
        <div className="fourth-section-pictures-container">
          <div
            data-aos="fade-down"
            data-aos-duration="2000"
            data-aos-offset="120"
            className="inline-image"
          >
            <img alt="stockPhoto" src={miljo1} />
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="200"
            className="inline-image"
          >
            <img alt="stockPhoto" src={miljo2} />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2400"
            data-aos-offset="100"
            className="inline-image"
          >
            <img alt="stockPhoto" src={miljo3} />
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="3000"
            data-aos-offset="60"
            className="inline-image"
          >
            <img alt="stockPhoto" src={miljo4} />
          </div>
          <img
            className="mobile-image-ourCulture"
            alt="mobile-picture"
            src={miljo4}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default OurCulture;
