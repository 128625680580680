import React, { useRef, useState } from "react";
import CountUpAnimation from "../CountUpAnimation";
import AvailableJobs from "./AvailableJobs";
import useIsVisible from "../hooks/ElementIsVisible";
import "./JobPosition.css";

const Positions = ({ jobsAvailable, jobsAvailableState }) => {
  const elemRef = useRef();
  const isVisible = useIsVisible(elemRef);

  return (
    <div className="third-section-container">
      <hr className="break-line" />
      <h2 id="available-positions" className="section-title">
        Akkurat nå har vi...
      </h2>
      <div className="third-section-data-container">
        <div className="jobs-available-number-container">
          <span
            className="jobs-data-value"
            data-aos="fade-right"
            data-aos-duration="2500"
          >
            <div ref={elemRef}>
              {isVisible && (
                <CountUpAnimation>{jobsAvailableState}</CountUpAnimation>
              )}
            </div>
            {/* {jobsAvailableState} */}
          </span>
          <span className="jobs-data-text">Ledige Stillinger</span>
        </div>
        <div className="current-employees-number-container">
          <span
            className="jobs-data-value"
            data-aos="fade-left"
            data-aos-duration="2500"
          >
            <div ref={elemRef}>
              {isVisible && <CountUpAnimation>370</CountUpAnimation>}
            </div>
          </span>
          <span className="jobs-data-text">Medarbeidere</span>
        </div>
      </div>
      <AvailableJobs jobsAvailable={jobsAvailable} />
    </div>
  );
};

export default Positions;
