import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
// import Content, { HTMLContent } from "../components/Content";

import BlogRoll from "../components/BlogRoll/BlogRoll";

import Aos from "aos";
import "aos/dist/aos.css";

import "./mainPage.css";

import VideoAndCtaButtons from "../components/pageSections/VideoAndCtaButtons/VideoAndCtaButtons";
import WhoAreWe from "../components/pageSections/WhoAreWe/WhoAreWe";
import OurCulture from "../components/pageSections/OurCulture/OurCulture";
import Technology from "../components/pageSections/Technology";
import ContactUs from "../components/pageSections/ContactUs";
import Positions from "../components/JobPositions/Positions";
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  const [jobsAvailableState, setAvailableJobsState] = useState(0);

  const jobsAvailable = (availableJobs) => {
    setAvailableJobsState(availableJobs);
  };

  useEffect(() => {
    Aos.init({
      startEvent: "load",
      once: false,
      mirror: false,
    });
  }, []);

  return (
    <>
      <VideoAndCtaButtons />
      <div className="page-column-container">
        <hr className="break-line" />
        <WhoAreWe />
        <Positions
          jobsAvailableState={jobsAvailableState}
          jobsAvailable={jobsAvailable}
        />
        <OurCulture />
        <Technology />
        <div className="blog-section-container">
          <hr className="break-line" />
          <h2 id="blog" className="section-title">
            Blogg
          </h2>
          <BlogRoll />
        </div>
        <ContactUs />
      </div>
    </>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout location={location}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
