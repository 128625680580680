import React from "react";

import MainImage from "../../../../static/img/autostore.jpg";
import autoStoreVideoMp4 from "../../../img/header-video.mp4";
import autoStoreVideoWebM from "../../../img/header-video.webm";

import { clickedOn } from "../../TrackingEvents";

import "./VideoAndCtaButtons.css";

const VideoAndCtaButtons = () => {
  return (
    <>
      <div className="first-section-container">
        <div className="video-wrapper">
          <video className="mainPage-video" autoPlay loop muted>
            <source src={autoStoreVideoMp4} type="video/mp4"></source>
            <source src={autoStoreVideoWebM} type="video/webm"></source>
          </video>
        </div>
        <div>
          <img
            alt="mobile-frontpage"
            className="mainPage-mobile-image"
            src={MainImage}
          />
        </div>
        <div className="title-wrapper">
          <div className="page-title">Jobbe hos oss?</div>
          <div className="call-to-action-container">
            <a
              onClick={(e) =>
                clickedOn("click open application", "on robot overlay")
              }
              className="call-to-action-button"
              href="https://emp.jobylon.com/jobs/219212-komplett-services-as-apen-soknad-web-utvikling/"
              target="_blank"
              rel="noreferrer"
            >
              Åpen Søknad
            </a>
            <a 
            className="call-to-action-button" 
            href="#available-positions">
              Ledige Stillinger
            </a>
          </div>
          <a href="#who-we-are" className="down-arrow"></a>
        </div>
      </div>
    </>
  );
};

export default VideoAndCtaButtons;
