import React from "react";

const GoogleMaps = () => {
  return (
    <iframe
      title="google-maps"
      className="google-maps-iframe"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2044.447800593603!2d10.21680721614978!3d59.175089630421816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4646c739daa874d9%3A0xdbe3d7997f1cc09b!2s%C3%98stre%20Kuller%C3%B8d%204%2C%203241%20Sandefjord!5e0!3m2!1sno!2sno!4v1632483027199!5m2!1sno!2sno"
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  );
};

export default GoogleMaps;
