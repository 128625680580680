import React from "react";

import KomplettBoxLogo from "../../img/logo-box.svg";
import "./JobPosition.css";

const JobPosition = ({ jobTitle, jobEngagement, jobLocation, jobUrl }) => {
  return (
    <a className="job-position-link" href={jobUrl}>
      <li className="job-container">
        <div className="job-image-container">
          <img alt="Komplett-Logo" src={KomplettBoxLogo}></img>
        </div>
        <div className="job-information-container">
          <div className="job-title">{jobTitle}</div>
          <hr className="job-hr" />
          <div className="job-engagement">{jobEngagement}</div>
          <div className="job-location">{jobLocation}</div>
        </div>
      </li>
    </a>
  );
};

export default JobPosition;
