import React, { useState, useEffect, useRef } from "react";
import * as THREE from "three";
import NET from "vanta/dist/vanta.net.min";

import "./Vanta.css";

import Aos from "aos";
import "aos/dist/aos.css";

import {
  AzureLogo,
  CssLogo,
  GatsbyLogo,
  HtmlLogo,
  JsLogo,
  ReactLogo,
  SqlLogo,
  CSharplogo,
  komplettTechLogo,
} from "../../img/index.js";

const Vanta = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect();
      NET({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: true,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
        color: 0xefba43,
        backgroundColor: 0xffffff,
        points: 4.0,
        maxDistance: 28.0,
        spacing: 19.0,
        THREE: THREE,
      });
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  return (
    <div>
      <div className="vanta-frame" ref={myRef}>
        <img
          alt="azure-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={AzureLogo}
        />
        <img
          alt="css-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={CssLogo}
        />
        <img
          alt="gatsby-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={GatsbyLogo}
        />
        <img
          alt="html-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={HtmlLogo}
        />
        <img
          alt="js-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={JsLogo}
        />
        <img
          alt="sql-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={SqlLogo}
        />
        <img
          alt="csharp-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={CSharplogo}
        />
        <img
          alt="react-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={ReactLogo}
        />
        <img
          alt="komplett-logo"
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="tech-logo"
          src={komplettTechLogo}
        />
      </div>
      <div className="mobile-icons-visibility">
        <div className="first-row-icons-flex">
          <img
            alt="azure-logo"
            className="tech-logo"
            src={AzureLogo}
            style={{ width: "70px" }}
          />
          <img
            alt="css-logo"
            className="tech-logo"
            src={CssLogo}
            style={{ width: "70px" }}
          />
          <img
            alt="gatsby-logo"
            className="tech-logo"
            src={GatsbyLogo}
            style={{ width: "70px" }}
          />
          <img
            alt="html-logo"
            className="tech-logo"
            src={HtmlLogo}
            style={{ width: "70px" }}
          />
        </div>
        <div className="second-row-icons-flex">
          <img
            alt="js-logo"
            className="tech-logo"
            src={JsLogo}
            style={{ width: "70px" }}
          />
          <img
            alt="sql-logo"
            className="tech-logo"
            src={SqlLogo}
            style={{ width: "70px" }}
          />
          <img
            alt="csharp-logo"
            className="tech-logo"
            src={CSharplogo}
            style={{ width: "70px" }}
          />
          <img
            alt="react-logo"
            className="tech-logo"
            src={ReactLogo}
            style={{ width: "70px" }}
          />
        </div>

        {/* <img alt="komplett-logo" className="tech-logo" src={komplettTechLogo} /> */}
      </div>
    </div>
  );
};

export default Vanta;
